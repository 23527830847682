'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitContactsManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitContactsManager', [
    '$mdDialog'
    'RestUtils'
    'UnitContactType'
    'ContactManager'
    'MundoDefaultManager'
    (
      $mdDialog
      RestUtils
      UnitContactType
      ContactManager
      MundoDefaultManager
    )->
      UnitContactsManagerBase = new MundoDefaultManager()
      UnitContactsManagerBase.setUrl('contacts')
      UnitContactsManagerBase.setAutoTenant()
      UnitContactsManagerBase.setNewObject(['unitContactType', 'contact'])
      UnitContactsManagerBase.setParentUrl('units')


      UnitContactsManagerBase.update = (id, data) ->
        if data.contact?
          data.contact = data.contact.id

        if @conf.parentId
          @transport.one(@conf.parentUrl, @conf.parentId).one(@getUrl(), id)
            .customPUT(_.pick(data, @getUpdateObject()))
        else
          @transport.one(@getUrl(), id).customPUT(_.pick(data, @getUpdateObject()))

      UnitContactsManagerBase.getEditForm = (data) ->
        [
          key: 'unitContactType'
          name: 'unitContactType'
          type: 'select'
          defaultValue: data.unitContactType.id
          templateOptions:
            label: 'Contact Type'
            placeholder: 'Contact Type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UnitContactType.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'contact'
          name: 'contact'
          type: 'autocomplete'
          defaultValue: data.contact
          templateOptions:
            label: 'Contact'
            placeholder: 'Contact'
            required: true
            displayValue: "label"
            options: ContactManager.getFullList({'sort' : 'label,ASC'}).$object
        ]

      UnitContactsManagerBase
  ]
